import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/employees", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/employees", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/employees/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/employees/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/employees/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const photoUpload = data => {
    return httpClient
        .post("/api/employees/photos", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const position = (options = {}) => {
    return httpClient
        .get("/api/employees/logic-positions", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const status = (options = {}) => {
    return httpClient
        .get("/api/employees/status", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const statusReason = (options = {}) => {
    return httpClient
        .get("/api/employees/status-reason", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const shift = (Option = {}) => {
    return httpClient
        .get("/api/employees/shifts", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const pcDate = (Option = {}) => {
    return httpClient
        .get("/api/employees/pc-date", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const paymentType = (Option = {}) => {
    return httpClient
        .get("/api/employees/payment-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const nationality = (Option = {}) => {
    return httpClient
        .get("/api/employees/nationalities", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const branch = (Option = {}) => {
    return httpClient
        .get("/api/employees/branches", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const moveBranch = data => {
    return httpClient
        .post("/api/employees/move-branches", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const storeContract = data => {
    return httpClient
        .post("/api/employees/store-contracts", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const previewPdf = data => {
    return httpClient
        .get("/api/employees/preview-pdf", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const province = (Options = {}) => {
    return httpClient
        .get("/api/customers/provinces", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const district = (province_code, Options = {}) => {
    return httpClient
        .get(`/api/customers/${province_code}/districts`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const commune = (district_code, Options = {}) => {
    return httpClient
        .get(`/api/customers/${district_code}/communes`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const village = (commune_code, Options = {}) => {
    return httpClient
        .get(`/api/customers/${commune_code}/villages`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const level = (Option = {}) => {
    return httpClient
        .get("/api/employees/levels", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const documentType = data => {
    return httpClient
        .get("/api/employees/document-types", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const removeFile = (Option = {}) => {
    return httpClient
        .delete("/api/employees/remove-files", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    find,
    update,
    destroy,
    photoUpload,
    position,
    status,
    shift,
    pcDate,
    paymentType,
    nationality,
    branch,
    moveBranch,
    statusReason,
    previewPdf,
    province,
    district,
    commune,
    village,
    level,
    documentType,
    storeContract,
    removeFile
};
