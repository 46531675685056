import coaSetup from "@/api/accounting/coa-setup";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    coaCategories: [],
    currencies: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_COA_CATEGORIES(state, data) {
        state.coaCategories = data;
    },
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    }
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attrubutes
        );
        return coaSetup.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return coaSetup.store(data);
    },
    update(context, { id, data }) {
        return coaSetup.update(id, data);
    },
    destroy(context, id) {
        return coaSetup.destroy(id);
    },
    changeIsActive(context, { id, data }) {
        return coaSetup.changeIsActive(id, data);
    },
    async getCoaCategory(context, attributes = {}) {
        let response = await coaSetup.coaCotegory({ params: attributes });
        context.commit("SET_COA_CATEGORIES", response.data);
    },
    async getCurrency(context, attributes = {}) {
        let response = await coaSetup.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    }
};
