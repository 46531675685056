import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/loan-types", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/loan-types", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/loan-types/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const changeIsSkipSunday = (id, data) => {
    return httpClient
        .put(`/api/loan-types/change-is-skip-sundays/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const changeIsActive = (id, data) => {
    return httpClient
        .put(`/api/loan-types/change-is-actives/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/loan-types/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const repaymentMethod = (Option = {}) => {
    return httpClient
        .get("/api/loan-types/repayment-methods", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, changeIsActive, currency, repaymentMethod, changeIsSkipSunday };
