import { baseState, baseMutations } from '@/store/mixins'
import leaveReport from '@/api/report/leave-report'

export const state = {
    ...baseState,
    employeeProfiles: []
}

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE_PROFILES (state, data) {
        state.employeeProfiles = data
    }
}
export const actions = {
    async getEmployeeProfile (context, attributes = {}) {
        let response = await leaveReport.employee({
            params: Object.assign({}, attributes)
        })
        context.commit('SET_EMPLOYEE_PROFILES', response.data)
    },
    leaveReportPdf (context, attributes = {}) {
        return leaveReport.leaveReport({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    exportExcel(context, { ...attributes }) {
        return leaveReport.exportExcel(Object.assign(
            {
                template: localStorage.getItem('langCode')
                    ? localStorage.getItem('langCode')
                    : 'en'
            },
            attributes
        ))
    }
}
